.homepage-wrapper {
  display: flex;
  justify-content: center;
  height: 60vh;
  align-items: center;

  .homepage {
    margin: 3rem;
    font-size: 1.3rem;
    width: fit-content;
    //text-shadow: 3px 3px 10px #000;

    .animated-row {
      margin: 0.5rem 0;
      animation: marg 10s;

      &.mobile .main {
        display: block;
      }

      .main, .right {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
      }

      .main {
        animation: showup 10s;
      }

      .right {
        width: auto;
        animation: reveal 10s;

        span {
          margin-left: 0;
          animation: slidein 10s;
        }
      }
    }
  }
}

@keyframes marg {
  0% {margin: 2.5rem 0;}
  30% {margin: 0.5rem 0;}
}

@keyframes showup {
  0% {opacity:0;}
  20% {opacity:1;}
}

@keyframes slidein {
  0% { margin-left:-100vw; }
  20% { margin-left:-100vw; }
  35% { margin-left:0; }
  100% { margin-left:0; }
}

@keyframes reveal {
  0% {opacity:0;width:0;}
  20% {opacity:1;width:0;}
  30% {width:auto;}
  80% {opacity:1;}
}
